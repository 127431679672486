<template>
  <v-row>
    <loading :active.sync="loading"></loading>
    <v-col>
      <span
        style="
          font-family: 'IBM Plex Sans Thai';
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 40px;
        "
      >
        เพิ่มภาพบรรยากาศ
      </span>
    </v-col>
    <v-card class="pa-6" width="100%">
      <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
        <v-row>
          <v-col cols="12" md="2" sm="2"
            ><span class="pt-2">ชื่อภาพบรรยากาศ (TH)</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="4" sm="4">
            <v-text-field
              hide-details
              v-model="title_th"
              placeholder="ชื่อภาพบรรยากาศ (TH)"
              dense
              outlined
              :rules="rules.required"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2" sm="2"
            ><span class="pt-2">ชื่อภาพบรรยากาศ (EN)</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="4" sm="4">
            <v-text-field
              hide-details
              v-model="title_en"
              placeholder="ชื่อภาพบรรยากาศ (EN)"
              dense
              outlined
              :rules="rules.required"
            ></v-text-field>
          </v-col>
          <!-- <v-col cols="12" md="2" sm="2"
            ><span class="pt-2">คำอธิบาย (TH)</span>
          </v-col>
          <v-col cols="12" md="4" sm="4">
            <v-text-field
                hide-details
              v-model="desc_th"
              placeholder="คำอธิบาย (TH)"
               
              dense
outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2" sm="2"
            ><span class="pt-2">คำอธิบาย (EN)</span>
          </v-col>
          <v-col cols="12" md="4" sm="4">
            <v-text-field
                hide-details
              v-model="desc_en"
              placeholder="คำอธิบาย (EN)"
               
              dense
outlined
            ></v-text-field>
          </v-col> -->
        </v-row>
        <!-- <v-row>
          <v-col cols="12" md="2" sm="2"
            ><span class="pt-2">ลิงค์ภาพบรรยากาศ</span>
          </v-col>
          <v-col cols="12" md="4" sm="4">
            <v-text-field
                hide-details
              v-model="bannerUrl"
              placeholder="ลิงค์ภาพบรรยากาศ"
               
              dense
outlined
            ></v-text-field>
          </v-col>
        </v-row> -->
        <v-row class="mt-10">
          <v-col cols="12" md="12" sm="12"
            ><span class="pt-2">รูปบรรยากาศ</span>
            <span style="color: red">*</span>
          </v-col>
          <v-col cols="12" md="12" sm="12" xs="12">
            <v-card outlined>
              <v-row no-gutters align="center">
                <v-col
                  cols="12"
                  md="11"
                  sm="11"
                  xs="11"
                  class="ma-4"
                  id="step-3"
                >
                  <span>เลือกรูปภาพภาพบรรยากาศ</span>
                  <span style="color: red">*</span>
                  <br />
                  <span style="color: red">อัพโหลดรูปภาพนามสกุล PNG ,JPEG</span>
                  <br />
                  <v-img :src="showImage" max-width="auto"></v-img>

                  <v-col cols="12" md="12" class="text-right">
                    <input
                      type="file"
                      accept="image/png, image/jpeg"
                      id="file"
                      ref="q_1_1"
                      v-on:change="handleFileUpload('q_1_1')"
                    />
                  </v-col>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-row align="center" justify="end">
          <v-btn @click="cancel()" class="mr-4">กลับ</v-btn>
          <v-btn @click="submit('q_1_1')" dark color="#2AB3A3">บันทึก</v-btn>
        </v-row>
      </v-form>
    </v-card>
  </v-row>
</template>
<script>
import { Decode } from "@/services";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      // createdAt: "2022-08-08T06:53:48.000Z"
      // desc: "b"
      // id: 2
      // img_url: "b"
      // sortindex: 1
      // title: "b"
      // updatedAt: "2022-08-08T06:53:48.000Z"

      title_en: null,
      title_th: null,
      desc_en: null,
      desc_th: null,
      img_url: null,
      // bannerDescription: "",
      activeFlag: true,
      pic1: "",
      picture_1: "",
      pic1URL: "",
      showImage: "",
      imageName1: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
        pic: [(v) => !!v || "กรุณาอัพโหลดรูป"],
      },
      checkValidate: true,
      lazy: false,
      user: "",
      linkUrl: "",
      productId: 0,
      items: [],
      category: "",
      itemsCategory: [],
      type: "",
      itemsType: [],
      brand: "",
      itemsBrand: [],

      loading: false,
      file: [],
    };
  },
  watch: {
    productId(val) {
      if (val) {
        console.log("productId", val);
      }
    },
  },
  computed: {
    isWindowWidthGreaterThan768() {
      return window.innerWidth > 768;
    },
  },
  created() {},
  methods: {
    async handleFileUpload(questionNo) {
      // this.data[questionNo] = this.$refs[questionNo].files[0];
      // console.log("file", this.data[questionNo]);
    },
    showPicture1(e) {
      // console.log('index',index);
      console.log("55");
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.picture_1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    async submitfileMain(questionNo) {
      if (this.$refs[questionNo].files[0]) {
        let formData = new FormData();
        formData.append("files", this.$refs[questionNo].files[0]);
        formData.append("filename", this.$refs[questionNo].files[0].name);
        formData.append("type", "uploadfile");
        // formData.append("userId", this.userId);
        console.log("formData", formData);
        const auth = {
          headers: {
            "Content-Type": "multipart/form-data",
            // Authorization: `Bearer` + " " + this.checkuser.token,
          },
        };
        console.log("auth", auth);
        const response = await this.axios.post(
          `https://gscm-service.yuzudigital.com/files/formData`,
          formData,
          auth
        );
        if (questionNo == "q_1_1") {
          this.img_url = response.data.data.path;
          this.showImage = response.data.data.path;
        }

        console.log("SUCCESS!!");
        console.log("response", response);
      }
    },
    async submit(pic) {
      console.log(this.file, "f");
      if (this.$refs.createForm.validate(true)) {
        // if (this.type == "CONTENT") {
        await this.submitfileMain(pic);
        console.log("pic", pic);
        // }
        this.user = JSON.parse(
          Decode.decode(localStorage.getItem("gscmAdmin"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        const data = {
          title_th: this.title_th,
          title_en: this.title_en,
          desc_en: this.desc_en,
          desc_th: this.desc_th,
          img_url: this.img_url,
          desc: this.desc,
          // bannerUrl: this.bannerUrl,
        };
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/imageAtmospheres`,
          data,
          auth
        );
        console.log("createBanner", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `บันทึกสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageBanners");
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.response_description,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    // async showImg(val) {
    //   this.loading = true;
    //   const response = await this.axios.get(
    //     `${process.env.VUE_APP_API}/products/` + val
    //   );
    //   console.log("getAllproducts", response.data.data);
    //   this.img = response.data.data.imgUrl;
    //   this.loading = false;
    // },

    cancel() {
      this.$router.push("ManageBanners");
    },
  },
};
</script>